import { useState } from "react";
import { Paper, BottomNavigation, BottomNavigationAction } from "@mui/material"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link, useLocation } from "react-router-dom";

const BottomNav = () => {
  const [value, setValue] = useState('discover')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const { pathname } = useLocation()

  if ( pathname !== '/' && pathname !== '/mypools' && pathname !== '/account') return null;
  
  // const url = '/pool'
  // if ( pathname.substring(0, 5) !== url.substring(0, 5)) return null;

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, paddingBottom: '35px', borderTop: '1px solid #CACACA'}}>
      <BottomNavigation
        value={value}
        onChange={handleChange}>
        <BottomNavigationAction 
          label="Discover" 
          value='discover'
          component={Link}
          to="/"
          icon={
            <SearchOutlinedIcon/>}
        />
        <BottomNavigationAction 
          label="My Pools" 
          value='mypools' 
          component={Link}
          to="/mypools"
          icon={
            <WavesOutlinedIcon/>}
        />
        <BottomNavigationAction 
          label="Account" 
          value='account' 
          component={Link}
          to="/account"
          icon={
            <AccountCircleOutlinedIcon/>}
        />
      </BottomNavigation>
    </Paper>
  )
}
export default BottomNav