import { useParams } from "react-router-dom"
import { Typography, Box } from "@mui/material"
import TopNavBar from "../components/TopNavBar"
import Section from "../components/Section"
import PriceSection from "../components/PriceSection"
import { Divider } from '@mui/material';
import Stocks from "../assets/Stocks.json"
import chart from "../assets/ChartData.json"
import { useEffect, useState } from "react"
 
const Stock = () => {
  const params = useParams()

  const [price, setPrice] = useState("...")
  const [change, setChange] = useState("...")

  const calcChange = (close, open) => {
    const amount = close - open
    const percentage = ((close / open)*100)-100
    return {
      amount: amount.toFixed(2),
      perc: percentage.toFixed(2)
      }
  }

  useEffect( () => {
    const loadStock = async () => {
      try {
        const stock = `stock1=${params.stock}`
        console.log(stock);
        const res = await fetch(`/.netlify/functions/alpaca?${stock}`).then( response => response.json() )
        console.log(res)
        setPrice(res.LatestTrade.Price)
  
        const close = res.DailyBar.ClosePrice //137.64
        const open = res.DailyBar.OpenPrice //135.945
        setChange(calcChange(close, open))
  
      } catch (error) {
          console.log(error)
      }
    }
    loadStock()
  }, [params.stock])

  const stock = Stocks.find( obj => obj.symbol === params.stock)
  return (
    <>
      {console.log(price)}
      <TopNavBar title={stock.symbol}/>
      <Box>
      <Section>
        <PriceSection 
          poolName={ stock.company }
          currentPrice={ price }
          change={ `$${change.amount} (${change.perc}%)` }
          chartData={ chart }
        ></PriceSection>
      </Section>
        <Divider />
      <Section>
        <Typography variant="body2">{stock.description}</Typography>
      </Section>
      </Box>
    </>
  )
}
export default Stock