import { Typography, Box } from "@mui/material"
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import TopNavBar from '../components/TopNavBar';

const initial = [{managerName: "no name", managerDesc: "no desc"}]

const About = ({ poolData }) => {
  const params = useParams()
  
  const [poolState, setPoolState] = useState(initial)

  useEffect( () => {
    try {
      const pool = poolData.find( obj => obj.id === params.name)
      setPoolState(pool)
    } catch (error) {
      return initial
    }
  }, [poolData, params])


  return (
    <>
      <TopNavBar title="About"/>
      <Box sx={{ padding: "24px" }}>
        <Typography variant="h5">About {poolState.name}</Typography>
        <Typography variant="body2">{poolState.desc}</Typography>
      </Box>
    </>
  )
}
export default About