import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { Box, Typography, ListItem, ListItemButton, ListItemText, ListItemSecondaryAction, Button } from "@mui/material"
import { useTheme } from '@mui/material/styles'

const initial = {
  name: "Pool Name",
  desc: "Pool Desc",
  price: 0,
  change: 0
}

const PoolCardMedium = ({ poolId, pools }) => {

  const theme = useTheme()

  const navigate = useNavigate()

  const [poolData, setPoolData] = useState(initial)

  function handleOnClick() {
    navigate(`/pool/${poolData.name}`)
  }

  useEffect( () => {
    try {
      const pool = pools.find(x => x.id === poolId)
      setPoolData(pool)
    } catch (error) {
      return initial
    }

  }, [pools, poolId])

  return (
    <Box sx={{border: '1px solid #E7E7E7', borderRadius: '6px', padding: '0px 0px'}} onClick={handleOnClick}>
      <ListItem sx={{padding: '0px 8px'}}>
        <ListItemButton>
          <ListItemText primary={poolData.name} secondary={`${poolData.desc.substring(0,30)}...`} primaryTypographyProps={theme.typography.h6} />
          <ListItemSecondaryAction sx={{textAlign: "right"}}>
            <Typography variant="h6">${poolData.lastPrice}</Typography>
            <Typography variant="body2" color="text.secondary">{(poolData.change * 100).toFixed(2)}%</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
      <Box sx={{padding: '0px 16px 8px 16px'}}>
        <Button variant='text' startIcon={<BookmarkBorderOutlinedIcon/>}>500</Button>
      </Box>
    </Box>
  )
}
export default PoolCardMedium