import { Box, Typography } from '@mui/material'
import PoolCardMedium from "./PoolCardMedium"

const CardStack = ({ title, description, poolIdList, pools }) => {

  return (
    <div>
      <Box sx={{marginBottom: '24px'}}>
        <Typography variant="h6" textAlign="left">{title}</Typography>
        <Typography variant="body2" textAlign="left" color="text.secondary" >{description}</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        <PoolCardMedium poolId={poolIdList[0]} pools={pools} />
        <PoolCardMedium poolId={poolIdList[1]} pools={pools} />
        <PoolCardMedium poolId={poolIdList[2]} pools={pools} />
      </Box>
    </div>
  )
}
export default CardStack