import { Button, ButtonGroup, Typography } from "@mui/material"
import { LineChart, Line} from "recharts"

const PriceSection = ({ poolName, currentPrice, change, chartData }) => {
  return (
    <div className="price">
      <div className="price-header">
        <Typography variant="overline">{poolName}</Typography>
        <Typography variant="h5">${currentPrice}</Typography>
        <Typography variant="caption">{change}</Typography>
      </div>
      <LineChart width={374} height={74} data={chartData}>
        <Line type="linear" dataKey="uv" stroke="#B3B3B3" r={0} fill="#B3B3B3"/>
        <Line type="linear" dataKey="pv" stroke="#000000" r={0} fill="#000000"/>
      </LineChart>
      <ButtonGroup variant="text" className="price-range" fullWidth={true}>
        <Button style={{borderRight: 0, fontWeight: 'bold'}}>1D</Button>
        <Button style={{borderRight: 0, fontWeight: 'bold'}}>1W</Button>
        <Button style={{borderRight: 0, fontWeight: 'bold'}}>1M</Button>
        <Button style={{borderRight: 0, fontWeight: 'bold'}}>6M</Button>
        <Button style={{borderRight: 0, fontWeight: 'bold'}}>1Y</Button>
        <Button style={{borderRight: 0, fontWeight: 'bold'}}>5Y</Button>
      </ButtonGroup>
    </div>
  )
}
export default PriceSection