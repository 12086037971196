import { Avatar, Typography } from "@mui/material"

const ProfileSection = ({ avatar, name, text }) => {
  return (
    <div className="profile">
      <Avatar className="profile-avatar" src={avatar}sx={{height: '66px', width: '66px'}}></Avatar>
      <div className="profile-text">
        <Typography variant="h6" textAlign="left">{name}</Typography>
        <Typography variant="body2" textAlign="left" color="text.secondary">{text}</Typography>
      </div>
    </div>
  )
}
export default ProfileSection