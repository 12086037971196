import { useParams } from "react-router-dom"
import { Typography, Box } from "@mui/material"
import TopNavBar from "../components/TopNavBar"

const Profile = ({ poolData }) => {
  const params = useParams()
  const pool = poolData.find( obj => obj.id === params.name)

  return (
    <>
      <TopNavBar title="Profile"/>
      <Box sx={{ padding: "24px" }}>
        <Typography variant="h5">Meet {pool.managerName}</Typography>
        <Typography variant="body2">{pool.managerDesc}</Typography>
      </Box>
    </>
  )
}
export default Profile