import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import StockList from '../components/StockList';
import Stack from '../components/Stack';
import Section from '../components/Section';
import ProfileSection from '../components/ProfileSection';
import AboutSection from '../components/AboutSection'
import PriceSection from '../components/PriceSection'
import TopNavBar from '../components/TopNavBar';
import avatarPhoto from '../assets/avatar-photo-fpo.png'
import chart from '../assets/ChartData.json'
import { Box } from '@mui/system';

const initial = {
  id: "pool Id",
  name: "pool name",
  desc: "pool desc",
  price: "pool price",
  change: "pool change",
  manager: {
    name: "manager name",
    desc: "manager desc"
  },
  portfolio: '[{"stock": "MMM", "percent": 38.27},{"stock": "DOW","percent": 10.68},{"stock": "XOM","percent": 9.89},{"stock": "IBM","percent": 9.03},{"stock": "RTX","percent": 8.89}]'
}

const Pool = ({ poolData }) => {

  const params = useParams()

  const [pool, setPool] = useState(initial)

  useEffect( () => {
    try {
      const poolObj = poolData.find( x => x.name === params.name)
      setPool(poolObj)

    } catch (error) {
      console.log(error);
      return initial
    }
  }, [poolData, params])


  return (
    <div className="App">
      <TopNavBar title=""/>
      <Stack
        key={ `${pool.id}-stack` }    
      >
        <Section
          key={ `${pool.id}-current-section` }>
          <PriceSection 
            poolName={ pool.name }
            currentPrice={ pool.lastPrice }
            change={`${(pool.change * 100).toFixed(2)}%`}
            chartData={ chart }
            key={ `${pool.id}-price` }
          ></PriceSection>
        </Section>
        <Divider 
          key={ `${pool.id}-divider-1` }
        />
        <Link
          to={`/about/${pool.id}`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
          key={ `${pool.id}-about-link` }
        >
          <Section>
            <AboutSection
              title={ `About ${pool.name}` }
              description={ pool.desc } 
              key={ `${pool.id}-about-section` }     
            />
          </Section>
        </Link>
        <Divider/>
        <Link
          to={`/profile/${pool.id}`} 
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
          key={ `${pool.id}-profile-link` }
        >
          <Section>
            <ProfileSection
              avatar={ avatarPhoto }
              name={ pool.managerName }
              text={ pool.managerDesc } 
              key={ `${pool.id}-profile-section` }  
            />
          </Section>
        </Link>
        <Divider/>
        <StockList
          stocks={ pool.portfolio }
        />
        <div
          style={{height: '160px'}}
        ></div> {/* last stock hidden under Trade CTA */}
      </Stack>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          backgroundColor: "white",
          width: "100%"
        }}
      >
      <Box
        sx={{
          padding: "16px 24px 45px 24px"
        }}
      >
        <Button
          variant="contained"
          size="large"
          sx={{
            borderRadius: "28px",
            width: "100%",
            height: "56px"
          }} disableElevation
        >Trade
        </Button>
      </Box>
      </div>
  </div>
  )
}
export default Pool