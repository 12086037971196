import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop';
import Discover from './pages/Discover';
import Pool from './pages/Pool'
import About from './pages/About'
import Profile from './pages/Profile'
import Stock from './pages/Stock'
import BottomNav from './components/BottomNav'
import './App.css'
import MyPools from './pages/MyPools';
import Account from './pages/Account';

function App() {
  const [user, setUser] = useState({})
  const [pools, setPools] = useState()

  const loadUser = async () => {
    try {
      const res = await fetch(`/.netlify/functions/users`)
      const userList = await res.json()
      const user = await userList[0]
      console.log("User loaded:", user)
      setUser(user)

    } catch (error) {
        console.log(error)
    }
  }

  const loadPools = async () => {
    try {
      const res = await fetch(`/.netlify/functions/pools`)
      const pools = await res.json()
      console.log("Pools loaded:", pools)
      setPools(pools)

    } catch (error) {
        console.log(error)
    }
  }

  useEffect( () => {
    loadUser()
    loadPools()
  }, [])


  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Discover {...user} pools={pools}/>} />
        <Route path="pool">
          <Route path=":name" element={<Pool poolData={pools}/>}/>
        </Route>
        <Route path="about">
          <Route path=":name" element={<About poolData={pools}/>}/>
        </Route>
        <Route path="profile">
          <Route path=":name" element={<Profile poolData={pools}/>}/>
        </Route>
        <Route path="stock">
          <Route path=":stock" element={<Stock poolData={pools}/>}/>
        </Route>
        <Route path="mypools" element={<MyPools {...user} pools={pools}/>} />
        <Route path="account" element={<Account />} />
      </Routes>
      <BottomNav />   
    </Router>
  )
}

export default App