import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { ListItemSecondaryAction, Typography } from '@mui/material';
import Stocks from '../assets/Stocks.json'

const initial = [{"stock": "MMM", "percent": 38.27},{"stock": "DOW","percent": 10.68},{"stock": "XOM","percent": 9.89},{"stock": "IBM","percent": 9.03},{"stock": "RTX","percent": 8.89}]


const StockList = ({ portfolio }) => {
  
  const [stockList, setStockList] = useState(initial)

  useEffect( () => {
    try {
      const parsedPortfolio = JSON.parse(portfolio)
      setStockList(parsedPortfolio)
    } catch (error) {
      return initial
    }
  }, [portfolio])


  const map = stockList.map( s => {
    let stock = Stocks.find(obj => obj.symbol === s.stock)
    return (
      <Link 
        to={`/stock/${stock.symbol}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
        key={`${stock.symbol}-a`}
      >

        <ListItem key={`${stock.symbol}-li`}>
          <ListItemButton key={`${stock.symbol}-lb`}>
            <ListItemText primary={stock.symbol} secondary={stock.company} key={`${stock.symbol}-lt`}/>
            <ListItemSecondaryAction sx={{textAlign: "right"}} key={`${stock.symbol}-sa`}>
              <Typography key={`${stock.symbol}-price`}>${stock.price}</Typography>
              <Typography variant="body2" color="text.secondary" key={`${stock.symbol}-percent`}>{s.percent}%</Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
        </ListItem>
      </Link>
    )
  })

  return (
    <>
      <List>
        {map}
      </List>
    </>
  )
}
export default StockList