import ParentPage from '../components/ParentPage'
import CardStack from '../components/CardStack';

function MyPools({ firstName, myPools }) {
  console.log(firstName);
  console.log(myPools);
  return (
    <ParentPage title={`${firstName}'s Pools`}>
      <CardStack poolIdList={myPools} />
    </ParentPage>
  );
}

export default MyPools;
