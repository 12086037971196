import ParentPage from '../components/ParentPage'

function Account() {
  return (
    <ParentPage title="Account">
      Some account stuff goes here
    </ParentPage>
  );
}

export default Account;
