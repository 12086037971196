import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from './App';
import './index.css';
import './App.css'


const theme = createTheme({
  palette: {
    primary: {
      light: '#174F90',
      main: '#193F6B',
      dark: '#162E4A',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h3: {
      fontWeight: 650,
      letterSpacing: "-0.2rem"
    },
    h4: {
      fontWeight: 650,
      letterSpacing: "-0.08rem"
    },
    h5: {
      fontWeight: 650,
      letterSpacing: "-0.05rem"
    },
    h6: {
      fontWeight: 650,
      fontSize: "1.125rem",
      letterSpacing: "-0.03rem"
    },
    button: {
      textTransform: 'inherit'
    }
  },
  components: {
    // Name of the component
    MuiBottomNavigationAction: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "rgb(0,0,0,0.4)",
          // Specifier
          "&.Mui-selected": {
          color: '#174F90'
        }
        }
      },
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App>
      </App>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
