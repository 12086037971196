import { Box, Typography } from "@mui/material"

const ParentPage = ({ title, children }) => {
  return (
    <Box sx={{marginTop: "25px", padding: "24px", display: 'flex', flexDirection: 'column', gap: '45px'}}>
    <Typography align="left" variant="h4">{ title }</Typography>
      { children }
    </Box>
  )
}

export default ParentPage