import CardStack from '../components/CardStack';
import ParentPage from '../components/ParentPage'
import { Card, CardMedia, CardContent, Typography } from '@mui/material'


function Discover({ pools }) {
  // const [state, setState] = useState("not fetched")


  // const onClickHandler = async () => {
  //   const params = [`stock1=AAPL`,`stock2=GOOG`]
  //   const response = await fetch(`/.netlify/functions/alpaca?${params[0]}&${params[1]}`).then( response => response.json() )
  //   setState(response.LatestTrade.Price)
  //   console.log("Fetch Alpaca response:", response);
  // }

  return (
    <ParentPage title="Discover">
      {/* <Button variant="outlined" onClick={onClickHandler}>Fetch Alpaca</Button>
      <h1>Price: ${state}</h1> */}
      <Card>
        <CardMedia
          component="img"
          height="140"
          image={process.env.PUBLIC_URL + '/donny-jiang-42gFAgdIUC8-unsplash.jpg'}
          alt="buildings"
        />
        <CardContent>
          <Typography variant="h6">
            Green investments
          </Typography>
          <Typography variant="body1">
            Investment opportunities that seek ways to use resources more sustainably
          </Typography>
        </CardContent>
      </Card>
      <CardStack title='Recommended Pools' description='Best pools personally curated for you.' poolIdList={['rect8zc1dkTQddeP2', 'recMVurf94ip0eJ1r', 'rec11EKaqXEccFIt3']} pools={pools} />
      <CardStack title='Hottest Pools' description='Top Pools in your area' poolIdList={['recCbBB8ghWGv1QRD', 'recMVurf94ip0eJ1r', 'rec1fBfGyLaPrhjB7', 'rec11EKaqXEccFIt3']} pools={pools}/>
    </ParentPage>
  );
}

export default Discover;
