import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate }  from 'react-router-dom'

const TopNavBar = ({ title }) => {
  let navigate = useNavigate()
  
  function handleClick() {
    navigate(-1)
  }

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 12px 8px 12px'}}>
      <IconButton
        onClick={handleClick}
      >
        <ArrowBackIcon
          color='primary'
        />
      </IconButton>
      <Typography variant="h6">{ title }</Typography>
      <IconButton>
        <HelpOutlineOutlinedIcon color='primary'/>
      </IconButton>
    </Box>
  )
}

export default TopNavBar