import { Button, Typography } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const AboutSection = ({ title, description }) => {
  return (
    <div className="about">
      <div className="about-content">
        <Typography variant="h6" textAlign="left">{title}</Typography>
        <Typography variant="body2" textAlign="left" color="text.secondary">{description}</Typography>
      </div>
      <Button className="about-cta" size="medium" endIcon={<ArrowForwardIcon fontSize="small" />}>See more</Button>
    </div>
  )
}
export default AboutSection